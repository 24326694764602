import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReportService } from '../../../../shared/services/report/report.service';
import { timezones } from '../../../../constants/timezone';
import moment from 'moment-timezone';

interface ParentItemData {
  date: string;
  result: number;
}

interface AccuracyData {
  title: string;
  result: string[];
}

interface InnerTableDate {
  date: string;
  data: ChildrenItemData[];
}

interface ChildrenItemData {
  key: number;
  task: string;
  result: number;
  accuracy: string;
}

interface UserData {
  id: number;
  email: string;
  full_name: string;
  uid: string;
  supervisor: boolean;
  oberver_uuid: string;
  allow_password_change: boolean;
}

@Component({
  selector: 'app-agent-report-accuracy-by-task',
  templateUrl: './agent-report-accuracy-by-task.component.html',
  styleUrls: ['./agent-report-accuracy-by-task.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class AgentReportAccuracyByTaskComponent implements OnInit {
  public listOfParentData: ParentItemData[] = [];
  public listOfChildrenData: ChildrenItemData[] = [];
  public innerTableDataList: InnerTableDate[] = [];
  public dateRange = [];
  private weekStartDate: string;
  private weekEndDate: string;
  public user: UserData;
  public facetNameList: string[] = [];
  public averageTableData: any[] = [];
  public accuracyData: AccuracyData[] = [];
  public selectedTimezone = null;
  public timezoneArray = timezones;

  private accuracyTableDictionary = {
    month_agent_report: 'Month to date',
    month_all_agent_report: 'All agent (Month to date)',
    year_agent_report: 'Year to date',
    year_all_agent_report: 'All agent (Year to date)',
  };

  constructor(private api: ReportService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.selectedTimezone = 'Asia/Manila';
    this.getDateForWeek();
    this.assignInitialDateRange();
    this.retrieveAgentResult(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
    this.retrieveAccuracySummary(this.selectedTimezone);
  }

  public timezoneChange(timezone) {
    this.innerTableDataList = [];
    this.selectedTimezone = timezone;
    this.getDateForWeek();
    this.assignInitialDateRange();
    this.retrieveAgentResult(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
    this.retrieveAccuracySummary(this.selectedTimezone);
  }

  public setDefaultTimeZone() {
    return this.selectedTimezone == null ? this.selectedTimezone = 'Asia/Manila' : this.selectedTimezone;
  }

  public onRangeSelect(result: Date): void {
    const range1 = (moment(result[0]).format()).split('T');
    const range2 = (moment(result[1]).format()).split('T');
    const startDateRange = moment.tz(range1[0], this.setDefaultTimeZone())
      .startOf('day')
      .toISOString();
    const endDateRange = moment.tz(range2[0], this.setDefaultTimeZone())
      .endOf('day')
      .toISOString();
    this.retrieveAgentResult(startDateRange, endDateRange, this.selectedTimezone);
  }

  public drillDownApiCall(date: string, state: boolean) {
    const formattedDate = date.split('T');
    const startDate = moment.tz(formattedDate[0], this.setDefaultTimeZone())
      .startOf('day')
      .toISOString();
    const endDate = moment.tz(formattedDate[0], this.setDefaultTimeZone())
      .endOf('day')
      .toISOString();
    if (state) {
      this.retrieveFacetResults(date, startDate, endDate, this.selectedTimezone);
    }
  }

  private getDateForWeek() {
    this.weekStartDate = moment
      .tz((moment.tz(this.setDefaultTimeZone()).format('YYYY-MM-DD')), this.setDefaultTimeZone())
      .subtract(7, 'days')
      .startOf('day')
      .toISOString();
    this.weekEndDate = moment
      .tz((moment.tz(this.setDefaultTimeZone()).format('YYYY-MM-DD')), this.setDefaultTimeZone())
      .subtract(1, 'days')
      .endOf('day')
      .toISOString();
  }

  private assignInitialDateRange() {
    const weekStartCalendar = this.splitTime(moment.tz(this.weekStartDate, this.setDefaultTimeZone()).format());
    const weekEndCalendar = this.splitTime(moment.tz(this.weekEndDate, this.setDefaultTimeZone()).format());
    const timeZone = moment.tz.guess();
    this.dateRange = [
      moment(this.splitTime(weekStartCalendar)).tz(timeZone).format('YYYY-MM-DD HH:MM:SS'),
      moment(this.splitTime(weekEndCalendar)).tz(timeZone).format('YYYY-MM-DD HH:MM:SS')
    ];
  }

  private splitTime(date: string) {
    if (date) {
      return date.split('T')[0];
    }
    return date;
  }

  public changeDateFormat(dateTime: string): string {
    const date = dateTime.split('T')[0];
    const dates = date.split('-');
    const formattedDate = [dates[1], dates[2], dates[0].substr(0, 2)].join("/");
    return formattedDate;
  }

  public retrieveAgentResult(startDate: string, endDate: string, zone: string) {
    this.api.retrieveAgentAccuracyData(startDate, endDate, zone).subscribe(
      res => {
        if (res) {
          this.listOfParentData = [];
          Object.values(res).forEach(keys => {
            this.listOfParentData.push({
              date: keys['date'],
              result: keys['count']
            });
          });
        }
      },
      error => console.log(error)
    );
  }

  public retrieveFacetResults(startDate: string, convertedStartDate: string, endDate: string, timeZone: string) {
    const faceted = 'true';
    this.api.getFacetAccuracyResult(convertedStartDate, endDate, faceted, timeZone).subscribe(
      res => {
        if (res) {
          const resultsArray = [];
          Object.values(res).forEach(keys => {
            resultsArray.push({
              key: 0,
              task: keys['facet'],
              result: keys['accurate'] + '/' + keys['count'],
              accuracy: keys['accuracy_percentage'] + ' %'
            });
          });
          this.listOfChildrenData = resultsArray;
          let counter = false;
          counter = this.innerTableDataList.some(key => {
            if (key.date === startDate) {
              return true;
            }
          });
          if (!counter) {
            this.innerTableDataList.push({ date: startDate, data: this.listOfChildrenData });
          }
        }
      },
      error => console.log(error)
    );
  }

  public getInnerTableDate(date: string) {
    const isoDate = moment.tz(date, this.setDefaultTimeZone()).toISOString();
    const data = this.innerTableDataList.filter(key => {
      if (key.date === isoDate) {
        return true;
      }
    });
    return data.length > 0 ? data[0].data : [];
  }

  private generateFacetArray(facetData) {
    facetData.year_agent_report.forEach(element => {
      if (!this.facetNameList.includes(element['facet'])) {
        this.facetNameList.push(element['facet']);
      }
    });
  }

  public retrieveAccuracySummary(timeZone: string) {
    this.api.retrieveAccuracyData(timeZone).subscribe(res => {
      if (res) {
        this.accuracyData = [];
        this.generateFacetArray(res);
        Object.keys(res).forEach(key => {
          if (this.accuracyTableDictionary.hasOwnProperty(key)) {
            const accuracyRow: AccuracyData = {
              title: '',
              result: []
            };
            accuracyRow.title = this.accuracyTableDictionary[key];
            this.facetNameList.forEach(facet => {
              let facetCounter = 0;
              res[key].forEach(element => {
                if (element.facet === facet) {
                  facetCounter++;
                }
              });
              if (facetCounter === 0) {
                accuracyRow.result.push('-');
              } else {
                accuracyRow.result.push(res['total_'+facet+'_accuracy_'+key] + ' %');
              }
            });
            accuracyRow.result.push(res['total_task_accuracy_' + key].toFixed(3) + ' %')
            this.accuracyData.push(accuracyRow);
          }
        });
      }
    });
  }
}
